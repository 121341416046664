import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modalElement"]

  static values = {
    opened: Boolean
  }

  connect() {
    document.addEventListener('keydown', (event) => {
      if (this.openedValue && event.key == 'Escape') {
        this.close()
      }
    })
  }

  open() {
    this.openedValue = true
    this.modalElementTarget.classList.remove("hidden")
  }

  close() {
    this.openedValue = false
    this.modalElementTarget.classList.add("hidden")
  }
}
