import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["inner"]
  static classes = [ "enabledOuter", "disabledOuter", "enabledInner", "disabledInner" ]

  toggle(event) {
    this.element.classList.toggle(this.enabledOuterClass)
    this.element.classList.toggle(this.disabledOuterClass)

    this.innerTarget.classList.toggle(this.enabledInnerClass)
    this.innerTarget.classList.toggle(this.disabledInnerClass)
  }
}
