import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['detailsPanel']
  static values  = { inactiveClass: String, requestId: String }

  showPanel(event) {
    const requestId = event.detail.requestId

    if (this.isPanelMatching(requestId)) {
      this.hideAllPanels()
      this.showCurrentPanel()
    }
  }

  showCurrentPanel() {
    this.element.classList.remove(this.inactiveClassValue)
  }

  hideAllPanels() {
    this.allPanels.forEach(el => {
      el.classList.add(this.inactiveClassValue)
    })
  }

  isPanelMatching(requestId) {
    return requestId == this.requestIdValue
  }

  get allPanels() {
    return document.querySelectorAll(`[data-controller="${this.identifier}"]`)
  }
}
