import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['contentToRemove']

  static values = {
    delayBeforeRemoval: Number
  }

  connect() {
    setTimeout(this.removeContent.bind(this), this.delayBeforeRemoval)
  }

  removeContent(event) {
    this.contentToRemoveTarget.remove()
  }

  get delayBeforeRemoval() {
    return this.delayBeforeRemovalValue || this.defaultDelayBeforeRemoval
  }

  get defaultDelayBeforeRemoval() {
    return 3000
  }
}
