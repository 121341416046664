import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener('keydown', (event) => {
      if (event.key == 'p') {
        if (this.element.getAttribute('open')) {
          this.element.removeAttribute('open')
        } else {
          this.element.setAttribute('open', true)
        }
      }
    })
  }
}
