import { Popover } from 'bootstrap';


const initBootstrapPopovers = () => {
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))

  popoverTriggerList.forEach((popoverTriggerEl) => {
    new Popover(
      popoverTriggerEl,
      {
        animation: false,
        html: popoverTriggerEl.dataset.popoverHtml == "true"
      }
    )
  })
}

export default initBootstrapPopovers
