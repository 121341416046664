import { Controller } from "@hotwired/stimulus";

import Cookies from "js-cookie"
import {enter, leave, toggle} from 'el-transition'

export default class extends Controller {
  static values = {
    opened: Boolean
  }

  static targets = ['sidebar', 'menu', 'backdrop', 'openButton', 'closeButton']

  open() {
    enter(this.menuTarget)
    enter(this.backdropTarget)
    enter(this.closeButtonTarget)

    Cookies.set('sidebar_opened', true)
    this.openedValue = true

    // Explicitely remove hidden class from inner target
    this.sidebarTarget.classList.remove('hidden')
  }

  close() {
    Cookies.set('sidebar_opened', false)
    this.openedValue = false

    leave(this.menuTarget)
    leave(this.closeButtonTarget)
    leave(this.backdropTarget)
  }
}
