import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "output", "input", "placeholder", "placeholderOutput" ]
  static values = { imageExists: Boolean }
  static classes = [ "placeholderHidden", "placeholderDisplayed", "placeholderOutputHidden", "outputHidden", "outputDisplayed" ]

  refreshPreview() {
    const input  = this.inputTarget

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = () => {
        if (this.imageExistsValue) {
          const output = this.outputTarget
          output.src = reader.result
        } else {
          const placeholder = this.placeholderTarget

          placeholder.classList.remove(this.placeholderDisplayedClass)
          placeholder.classList.add(this.placeholderHiddenClass)

          const placeholderOutput = this.placeholderOutputTarget

          placeholderOutput.classList.remove(this.placeholderOutputHiddenClass)
          placeholderOutput.src = reader.result
        }
      }

      reader.readAsDataURL(input.files[0]);
    }
  }
}
