import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.inputTarget.focus()
  }

  betterFocus(_) {
    const temp_value = this.inputTarget.value
    this.inputTarget.value = ''
    this.inputTarget.value = temp_value
  }
}
