// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus"

import Dropdown from 'stimulus-dropdown'
import ReadMore from 'stimulus-read-more'
import TextareaAutogrow from 'stimulus-textarea-autogrow'

import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

application.register('dropdown', Dropdown)
application.register('read-more', ReadMore)
application.register('textarea-autogrow', TextareaAutogrow)

const context = require.context(".", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
