// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import "./channels"
import "chartkick/chart.js"

import "form-request-submit-polyfill/form-request-submit-polyfill.js"

Rails.start()


import "./controllers"

// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";

// Internal imports
import './plugins/initSweetAlert';
import initBootstrapPopovers from './plugins/initBootstrapPopovers';
import initBootstrapTooltips from './plugins/initBootstrapTooltips';
import initGithubHotkeys     from './plugins/initGithubHotkeys';
import initSmarties          from './plugins/initSmarties';

Chartkick.config.autoDestroy = false

window.addEventListener('turbo:before-render', () => {
  Chartkick.eachChart(chart => {
    if (!chart.element.isConnected) {
      chart.destroy()
      delete Chartkick.charts[chart.element.id]
    }
  })
})

document.addEventListener('turbo:load', () => {
  initBootstrapTooltips()
  initBootstrapPopovers()
  initGithubHotkeys()
  initSmarties()

  // Fixing issue when rendering a form with `:url` option setup, after click on link handled through turbolinks
  // https://github.com/rails/rails/issues/24257#issuecomment-705373212
  Rails.refreshCSRFTokens();

  document.querySelectorAll('[aria-expanded=true]').forEach(item => {
    item.setAttribute('aria-expanded', true)
  })

  document.addEventListener('keydown', (event) => {
    if (event.key == "Escape") {
      document.activeElement.blur()
    }
  })

  function setVhVariable() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  // Écouter l'événement resize et mettre à jour la variable CSS
  window.addEventListener('resize', setVhVariable);
  window.addEventListener('orientationchange', setVhVariable);

  // Définir la variable lors du chargement initial de la page
  setVhVariable();

  // Fixing issue with rails-ujs disable with, when setting a link_to with a block to have rich inner HTML for
  // our links (with an icon and some text for example).

  // See @rails/ujs/lib/assets/compiled/rails-ujs.js:432
  // rails-ujs does not handle this case properly, as the click event.target will return
  // either the icon, or the text, depending on where the user clicks.

  const buttonsToDisable = document.querySelectorAll("[data-homemade-disable-with]");

  buttonsToDisable.forEach((button) => {
    button.addEventListener('click', (event) => {
      setTimeout(() => {
        button.disabled  = true;
        button.innerHTML = button.dataset.homemadeDisableWith;
        button.classList.add('disabled');
      }, 13)
    });
  });
});
