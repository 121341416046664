import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['listingOverview']
  static values  = { activeClass: String, requestId: String }

  connect() {
    this.listenForFocusEvent()
    this.focusActiveElementOnFirstLoad()
  }

  listenForFocusEvent() {
    document.addEventListener('keydown', (event) => {
      if (event.key == 'Enter' && document.activeElement == this.element) {
        this.showDetails()
      }
    })
  }

  focusActiveElementOnFirstLoad() {
    if (this.element.classList.contains(this.activeClassValue)) {
      this.activateCurrentElement()
    }
  }

  showDetails() {
    this.deactivateAllListingOverviews()
    this.activateCurrentElement()
    this.dispatchShowPanelEvent()
  }

  activateCurrentElement() {
    this.element.classList.add(this.activeClassValue)
    this.element.setAttribute('tabindex', '-1')
    this.element.focus()
  }

  deactivateAllListingOverviews() {
    this.allListingOverviews.forEach(el => {
      el.classList.remove(this.activeClassValue)
      el.setAttribute('tabindex', '0')
      el.blur()
    })
  }

  dispatchShowPanelEvent() {
    const event = new CustomEvent("show-request-details", {
      detail: {
        requestId: this.requestIdValue
      }
    })

    document.dispatchEvent(event)
  }

  get allListingOverviews() {
    return document.querySelectorAll(`[data-controller="${this.identifier}"]`)
  }
}
