import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tab', 'tabPanel']

  static values = {
    activeTabClasses: String,
    inactiveTabClasses: String,
    activeTabPanelClasses: String,
    inactiveTabPanelClasses: String
  }

  change(event) {
    this.tabTargets.forEach((tab) => this.removeTabActive(tab))
    this.addTabActive(event.currentTarget)

    this.tabPanelTargets.forEach((tabPanel) => this.hideTabPanel(tabPanel))
    this.displayActiveTabPanel(event.currentTarget)
  }

  removeTabActive(tab) {
    tab.classList.remove(...this.activeTabClassesValue.split(' '))
    tab.classList.add(...this.inactiveTabClassesValue.split(' '))
  }

  addTabActive(tab) {
    tab.classList.remove(...this.inactiveTabClassesValue.split(' '))
    tab.classList.add(...this.activeTabClassesValue.split(' '))
  }

  hideTabPanel(tabPanel) {
    tabPanel.classList.remove(this.activeTabPanelClassesValue.split(' '))
    tabPanel.classList.add(this.inactiveTabPanelClassesValue.split(' '))
  }

  displayActiveTabPanel(activeTab) {
    const activeTabIndex = this.tabTargets.indexOf(activeTab)
    const activePanel = this.tabPanelTargets[activeTabIndex]

    activePanel.classList.add(this.activeTabPanelClassesValue.split(' '))
    activePanel.classList.remove(this.inactiveTabPanelClassesValue.split(' '))
  }
}
