import tippy from 'tippy.js';

const parseHTML = (html) => {
  var t = document.createElement('template');
  t.innerHTML = html;
  return t.content;
}

const initSmarties = () => {
  const smartiesJSONScript = document.querySelector("script[smarties-data]")
  if (smartiesJSONScript) {
    const json_data = smartiesJSONScript.innerText

    const smartiesData = JSON.parse(json_data)

    smartiesData.packets.forEach(packet => {
      let packetElement = document.querySelector(packet.attached_to)

      if (packetElement) {
        const beaconElement = parseHTML(packet.beacon_html)
        packetElement.appendChild(beaconElement);

        packet.candies.forEach(candy => {
          let candyElement = document.querySelector(candy.attached_to)

          const beaconElement = parseHTML(candy.beacon_html)
          candyElement.appendChild(beaconElement);

          tippy(candyElement, {
            content: candy.tooltip_html,
            allowHTML:   true,
            interactive: true,
            appendTo:    () => document.body,
            arrow:       true,
            inertia:     true,
            popperOptions: {
              modifiers: [
                {
                  name: 'flip',
                  options: {
                    fallbackPlacements: ['bottom'],
                    rootBoundary: 'document',
                    padding: 8,
                  },
                },
              ],
            }
          });
        })
      }
    })
  }
}

export default initSmarties
