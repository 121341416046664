import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['elementToPrint', 'innerElement']
  static values = {
    wrapperStyle: String
  }

  triggerPrint(event) {
    const elementAsFrame = document.createElement('IFRAME');
    elementAsFrame.domain = document.domain;
    elementAsFrame.style.top = "-10000px";

    document.body.appendChild(elementAsFrame);

    const clonedWrapper = this.elementToPrintTarget.cloneNode()
    clonedWrapper.style = this.wrapperStyleValue;

    let content = ""

    this.innerElementTargets.forEach((innerElement) => {
      let clonedElement = innerElement.cloneNode(true)
      clonedElement.style = innerElement.dataset.printElementStyleValue

      content += clonedElement.outerHTML
    })

    clonedWrapper.innerHTML = content

    const iframeContent = clonedWrapper.outerHTML + this.printStyleTag
    elementAsFrame.contentDocument.write(iframeContent);

    setTimeout(() => {
      elementAsFrame.focus();
      elementAsFrame.contentWindow.print();
      elementAsFrame.parentNode.removeChild(elementAsFrame) ;
    }, 1000); // wait for images to load inside iframe
    window.focus();
  }

  get printStyleTag() {
    return `
      <style type="text/css" media="print">
      @page {
          size: auto;
          margin: 0;
      }
      </style>
    `
  }
}
