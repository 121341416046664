import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = []
  static values = {
    path: String,
    params: Object,
    sendFormCurrentInputs: Boolean
  }

  async refresh(event) {
    event.preventDefault()
    const triggerinitialHTML = event.currentTarget.innerHTML

    event.currentTarget.innerHTML = `${triggerinitialHTML}<i class="fa-solid fa-spinner fa-spin-pulse fa-fw ml-2"></i>`

    const url = new URL(this.pathValue)

    this.appendParamsToUrl(url, this.paramsValue);

    if (this.sendFormCurrentInputsValue) {
      const form = event.target.closest('form')

      if (form) {
        const formData = new FormData(form)

        formData.forEach((value, key) => {
          url.searchParams.set(key, value)
        })
      }
    }

    try {
      const response = await fetch(url.toString(), {
        headers: {
          'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml'
        }
      })

      if (response.ok && response.headers.get('Content-Type').includes('vnd.turbo-stream.html')) {
        const body = await response.text()

        Turbo.renderStreamMessage(body)
      } else {
        console.error('Response was not a Turbo Stream')
      }
    } catch (error) {
      console.error('Failed to fetch Turbo Stream', error)
    }
  }

  appendParamsToUrl(url, params, prefix = '') {
    Object.entries(params).forEach(([key, value]) => {
      const paramName = prefix ? `${prefix}[${key}]` : key;

      if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
        this.appendParamsToUrl(url, value, paramName);
      } else {
        url.searchParams.set(paramName, value);
      }
    });
  }
}
