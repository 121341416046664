import { Controller } from "@hotwired/stimulus";
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = ['innerPanelContent']

  connect() {
    useDispatch(this, { eventPrefix: false })
  }

  trigger(event) {
    event.preventDefault()
    this.dispatch(this.sidePanelTriggerEvent, { innerPanelContent: this.innerPanelContentTarget.innerHTML })
  }

  get sidePanelTriggerEvent() {
    return this.sidePanel.dataset["sidePanel-SlideOverTriggerEventValue"]
  }

  get sidePanel() {
    return document.querySelector('[data-controller="side-panel--slide-over"]')
  }
}
