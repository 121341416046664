import { Controller } from "@hotwired/stimulus";
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = ["clickableBackdrop", "closeButton"]

  connect() {
    useDispatch(this, { eventPrefix: false })
  }

  close(event) {
    if (event.target == this.clickableBackdropTarget || this.closeButtonTargets.includes(event.target)) {
      // Prevents click anywhere inside modal (form or others) to close the modal
      // as well
      this.dispatch(this.modalCloseEvent, {})
    }
  }

  get modalCloseEvent() {
    return this.modal.dataset["modal-ModalCloseEventValue"]
  }

  get modal() {
    return document.querySelector('[data-controller*="modal--close"]')
  }
}
