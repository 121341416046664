const betterFocusOnInput = (input, options = {}) => {
  const { focus = false } = options;

  // Triggering a re-render by clearing and resetting the input value
  const tempValue = input.value;
  input.value = '';
  input.value = tempValue;

  // Optionally focus the input field
  if (focus) {
    input.focus();
  }
}

export { betterFocusOnInput };
