import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["emitterElement", "receiverElement"]

  updatePreview(event) {
    const emitterElement = event.currentTarget
    const elementEmittingAttribute = emitterElement.dataset["utils-PreviewEmittingAttribute"]

    const elementReceivingValueUpdate = this.receiverElementTargets.find((element) => {
      return element.dataset["utils-PreviewReceivingAttribute"] === elementEmittingAttribute
    })

    const propertiesToUpdate    = JSON.parse(elementReceivingValueUpdate.dataset["utils-PreviewPropertiesToUpdate"])
    const emitterValueToPreview = emitterElement.dataset["utils-PreviewEmittingValue"]

    propertiesToUpdate.forEach((propertyToUpdate) => {
      elementReceivingValueUpdate[propertyToUpdate] = emitterValueToPreview
    })
  }
}
