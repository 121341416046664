import { Controller } from "@hotwired/stimulus"
import Clipboard from 'stimulus-clipboard'

export default class extends Clipboard {
  static targets = ["icon"]

  static values = {
    iconBaseClass: String,
    iconCopiedClass: String
  }

  connect() {
    super.connect()
  }

  copy(event) {
    this.iconTarget.classList.remove(...this.iconBaseClassValue.split(' '))
    this.iconTarget.classList.add(...this.iconCopiedClassValue.split(' '))

    setTimeout(() => {
      this.iconTarget.classList.remove(...this.iconCopiedClassValue.split(' '))
      this.iconTarget.classList.add(...this.iconBaseClassValue.split(' '))
    }, 1200)

    super.copy(event)
  }
}
