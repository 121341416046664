import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modalContent", "modalWrapper"]

  static values = {
    opened: Boolean,
    openEvent: String,
    closeEvent: String
  }

  connect() {
    document.addEventListener('keydown', (event) => {
      if (this.openedValue && event.key == 'Escape') {
        this.close()
      }
    })

    document.addEventListener(this.openEventValue, this.open.bind(this))
    document.addEventListener(this.closeEventValue, this.close.bind(this))
  }

  open(event) {
    this.openedValue = true

    this.modalContentTarget.innerHTML = event.detail.modalContent
    this.modalWrapperTarget.classList.remove("hidden")
  }

  close(event) {
    this.openedValue = false

    this.modalContentTarget.innerHTML = ""
    this.modalWrapperTarget.classList.add("hidden")
  }
}
