import { Controller } from "@hotwired/stimulus"
import { betterFocusOnInput } from "../utils/better_focus_on_input"

// Connects to data-controller="multi-mode-input"
export default class extends Controller {
  static targets = [ "inputWrapper", "forwardInput", "backwardInput" ]
  static classes = [ "visible", "hidden" ]

  switchVisibleInput() {
    this.inputWrapperTargets.forEach(wrapper => {
      if (wrapper.classList.contains(this.hiddenClass)) {
        wrapper.classList.remove(this.hiddenClass)
        wrapper.classList.add(this.visibleClass)

        betterFocusOnInput(wrapper.querySelector('input'), { focus: true })
      } else {
        wrapper.classList.remove(this.visibleClass)
        wrapper.classList.add(this.hiddenClass)
      }
    })
  }

  syncInputsValues(event) {
    if (event.currentTarget == this.backwardInput) {
      this.forwardInput.value = this.valueForwardCastedValue
    } else if (event.currentTarget == this.forwardInput) {
      this.backwardInput.value = this.valueBackwardCastedValue
    }
  }

  get valueForwardCastedValue() {
    return eval(this.element.dataset.multiModeInputValueForwardCastingFunction)(this.backwardValue)
  }

  get valueBackwardCastedValue() {
    return eval(this.element.dataset.multiModeInputValueBackwardCastingFunction)(this.forwardValue)
  }

  get backwardValue() {
    const rawValue = this.backwardInput.value
    return this.valueParsingFunction(rawValue)
  }

  get forwardValue() {
    const rawValue = this.forwardInput.value
    return this.valueParsingFunction(rawValue)
  }

  get forwardInput() {
    return this.forwardInputTarget
  }

  get backwardInput() {
    return this.backwardInputTarget
  }

  get valueParsingFunction() {
    return eval(this.element.dataset.multiModeInputValueParsingFunction)
  }
}
