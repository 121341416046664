import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

// https://diserve-it.com/post/using-sweet-alert-as-confirm-in-ruby-on-rails-6

window.Swal = Swal;

// Behavior after click to confirm button
const confirmed = (element, result) => {
  if (result.value) {
    element.removeAttribute('data-confirm-swal');
    element.click();
  }
};

// Display the confirmation dialog
const showConfirmationDialog = (element) => {
  const icon              = element.getAttribute('data-confirm-swal-icon') || "warning"
  const message           = element.getAttribute('data-confirm-swal')
  const text              = element.getAttribute('data-confirm-swal-text')
  const confirmButtonText = element.getAttribute('data-confirm-swal-button-text')
  const cancelButtonText  = element.getAttribute('data-cancel-swal-button-text')

  Swal.fire({
    title:             message,
    html:              text,
    icon:              icon,
    showCancelButton:  true,
    confirmButtonText: confirmButtonText,
    cancelButtonText:  cancelButtonText,
    reverseButtons:    true,
  }).then(result => confirmed(element, result))
};

const allowAction = (element) => {
  if (element.getAttribute('data-confirm-swal') === null) {
    return true;
  }

  showConfirmationDialog(element);
  return false;
};

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  }
}

Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleConfirm);
