import { Controller } from "@hotwired/stimulus";
import { useDispatch } from 'stimulus-use'
import { csrfToken } from "@rails/ujs"

export default class extends Controller {
  static values = {
    contentUrl: String,
    contentFetchMethod: String
  }

  static targets = ['modalContent']

  connect() {
    useDispatch(this, { eventPrefix: false })
  }

  open() {
    if (this.contentUrlValue) {
      const fetchMethod = this.contentFetchMethodValue || "GET"

      fetch(this.contentUrlValue, { method: fetchMethod, headers: { "Accept": "text/plain", "X-CSRF-Token": csrfToken() } })
        .then(response => response.text())
        .then(html => {
          this.dispatch(this.modalOpenEvent, { modalContent: html })
        })
    } else {
      this.dispatch(this.modalOpenEvent, { modalContent: this.modalContentTarget.innerHTML })
    }
  }

  close() {
    this.dispatch(this.modalCloseEvent, {})
  }

  get modalOpenEvent() {
    return this.modal.dataset["modal-ModalOpenEventValue"]
  }

  get modalCloseEvent() {
    return this.modal.dataset["modal-ModalCloseEventValue"]
  }

  get modal() {
    return document.querySelector('[data-controller*="modal--modal"]')
  }
}
