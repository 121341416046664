import { Controller } from "@hotwired/stimulus"
import {enter, leave, toggle} from 'el-transition'

export default class extends Controller {
  static values = {
    triggerEvent: String,
    panelOpen:    Boolean
  }

  static targets = ['innerPanel', 'panelWrapper', 'backdrop', 'closeButton']

  connect() {
    document.addEventListener(this.triggerEventValue, this.open.bind(this))

    document.addEventListener('keydown', (event) => {
      if (event.key == 'Escape') {
        this.close()
      }
    })
  }

  open(event) {
    this.panelOpen = true

    this.innerPanelTarget.innerHTML = event.detail.innerPanelContent
    enter(this.innerPanelTarget)
    enter(this.backdropTarget)

    this.panelWrapperTarget.classList.remove('hidden')
  }

  close() {
    this.panelOpen = false

    leave(this.innerPanelTarget)
    leave(this.backdropTarget)
  }
}
