import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "fileInput", "uploadButton"]

  onFileInputChange(e) {
    this.formTarget.requestSubmit();

    this.fileInputTarget.disabled = true
    this.uploadButtonTarget.classList.add('cursor-not-allowed')
  }
}
